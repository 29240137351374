/* Overriding Blueprint's Timepicker component style -- this was a bear and a bad idea. */

.bp3-timepicker-input-row {
  width: 100%;
  height: 2.5rem !important;
  border: 1px solid;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  font-size: 1rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  box-shadow: none !important;
  border-color: inherit !important;
  display: flex !important;
  flex-direction: row !important;
  line-height: auto !important;
  align-items: center;
}

.bp3-timepicker-input-row:focus-within {
  z-index: 1 !important;
  border-color: #3182ce !important;
  box-shadow: 0 0 0 1px #3182ce !important;
}

.bp3-timepicker-row:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bp3-timepicker-input {
  text-align: left !important;
  width: 2rem !important;
  padding-left: 1rem !important;
} 

.bp3-timepicker-hour {
  padding-left: 1rem !important;
}

.bp3-timepicker-minute {
  padding-left: .3rem !important;
}

.bp3-timepicker-divider-text {
  width: auto !important;
  padding: 0 0 .1rem .3rem;
}

.bp3-timepicker-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bp3-timepicker-input-row:hover {
  border-color: #CBD5E0;
}
